<template>
    <div class="walletTest">
        <div class="user">
            <p>{{selectedAddress}}</p>
            <br>
            <el-button @click="connectWallet">connect wallet</el-button>
            <el-button @click="mint">mint</el-button>
            <el-button @click="getPrice">getPrice</el-button>
            <el-button @click="getTotal">getTotal</el-button>
            <el-button @click="checkUser">checkUser</el-button>
            <el-button @click="balanceOf">balanceOft</el-button>
            <el-button @click="tokenOfOwnerByIndex">tokenOfOwnerByIndex</el-button>
            <el-button @click="tokenURI">tokenURI</el-button>
            

        </div>

        <br>

        <div class="admin">
            <el-button @click="withdraw" type="primary">withdraw</el-button>
            <el-button @click="setWhiteList" type="primary">setWhiteList</el-button>
            <el-button @click="setPrice" type="primary">setPrice</el-button>
            <el-button @click="setWprice" type="primary">setWprice</el-button>
            <el-button @click="paused" type="primary">paused</el-button>
            <el-button @click="setTotal" type="primary">setTotal</el-button>

        </div>
    </div>
</template>

<script>
    import Web3 from 'web3'
    import { getContract, mint, getPrice, getTotal, checkUser, balanceOf, tokenOfOwnerByIndex } from './../../utils/contract'
    export default {
        data() {
            return {
                key: 'value'
            }
        },
        computed: {
            account() {
                return this.$store.state.web3Modal.account;
            },
            selectedAddress() {
                return this.$store.state.web3Modal.selectedAddress;
            }
        },
        methods: {
            connectWallet() {
                this.$store.dispatch('connect');
            },
            async mint() {
                const Contract = await getContract();
                console.log('contract', Contract);
                Contract.methods.mint()
                .send({
                    value: Math.pow(10,13),
                    from: localStorage.getItem('account')
                })
                .then(res=>{
                    console.log('getPrice res', res)
                })
                .catch(err=>{
                    console.error('err', err)
                })
            },
            async getPrice() {
                const Contract = await getContract();
                Contract.methods
                .getPrice()
                .call()
                .then(res=>{
                    console.log('getPrice res', res)
                })
                .catch(err=>{
                    console.error('err', err)
                })
            },
            async getTotal() {
                const Contract = await getContract();
                Contract.methods
                .getTotal()
                .call()
                .then(res=>{
                    console.log('getTotal    res', res)
                })
                .catch(err=>{
                    console.error('err', err)
                })
            },
            async checkUser() {
                const Contract = await getContract();
                Contract.methods
                .checkUser(localStorage.getItem('account'))
                .call()
                .then(res=>{
                    console.log('checkUser    res', res)
                })
                .catch(err=>{
                    console.error('err', err)
                })
            },
            async balanceOf() {
                balanceOf()
                .then(res=>{
                    console.log('balanceOf res', res)
                })
            },
            async tokenOfOwnerByIndex() {
                const Contract = await getContract();
                Contract.methods
                .tokenOfOwnerByIndex(localStorage.getItem('account'), 0)
                .call({
                    from: localStorage.getItem('account'),
                })
                .then(res=>{
                    console.log('res', res)
                })
            },
            async tokenURI() {
                const Contract = await getContract();
                Contract.methods
                .tokenURI(1)
                .call()
                .then(res=>{
                    console.log('res', res)
                })
            },
            
            async withdraw() {
                const Contract = await getContract();
                console.log('Contract', Contract)
                Contract.methods
                .withdraw(localStorage.getItem('account'))
                .send({
                    from: localStorage.getItem('account'),
                })
                .then(res=>{
                    console.log('withdraw res', res)
                })
                .catch(err=>{
                    console.error('err', err)
                })
            },
            async setWhiteList() {
                const Contract = await getContract();
                console.log('Contract', Contract)
                Contract.methods
                .setWhiteList(['0x854bFcD222f52E0e69Ad8133603E8Ab076a3b80A', '0x136688F6A03C5893C9156FCC0f6F5C7366045B7b'])
                .send({
                    from: localStorage.getItem('account'),
                })
                .then(res=>{
                    console.log('setWhiteList res', res)
                })
                .catch(err=>{
                    console.error('setWhiteList err', err)
                })
            },
            async setPrice() {
                const Contract = await getContract();
                console.log('Contract', Contract)
                window.methods = Contract.methods
                Contract.methods
                .setPrice(Math.pow(10,12))
                .call({
                    from: localStorage.getItem('account'),
                })
                .then(res=>{
                    console.log('setPrice res', res)
                })
                .catch(err=>{
                    console.error('err', err)
                })
            },
            async setWprice() {
                const Contract = await getContract();
                console.log('Contract', Contract)
                Contract.methods
                .setWprice("120000000000000000")
                .send({
                    from: localStorage.getItem('account'),
                })
                .then(res=>{
                    console.log('setWprice res', res)
                })
                .catch(err=>{
                    console.error('err', err)
                })
            },
            async paused() {
                const Contract = await getContract();
                console.log('Contract', Contract)
                Contract.methods
                .paused(true)
                .send({
                    from: localStorage.getItem('account'),
                })
                .then(res=>{
                    console.log('paused res', res)
                })
                .catch(err=>{
                    console.error('err', err)
                })
            },
            async setTotal() {
                const Contract = await getContract();
                console.log('Contract', Contract)
                Contract.methods
                .setTotal("0x1388")
                .send({
                    from: localStorage.getItem('account'),
                })
                .then(res=>{
                    console.log('setTotal res', res)
                })
                .catch(err=>{
                    console.error('err', err)
                })
            }
        },
        mounted () {
            this.$store.dispatch('init');
        },
    }
</script>

<style lang="less" scoped>
    .walletTest{
        padding: 20px;
    }
</style>